import React, { useEffect, useState } from 'react';
import { Card, Button, List, notification } from 'antd';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase';
import { useNavigate } from 'react-router-dom';

const OpenChats = () => {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const chatsRef = ref(database, 'chats');
    
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const openChats = [];
      snapshot.forEach((snap) => {
        const chat = { id: snap.key, ...snap.val() };

        // Пример добавления уведомлений о непрочитанных сообщениях
        const messagesRef = ref(database, `chats/${chat.id}/messages`);
        onValue(messagesRef, (messageSnapshot) => {
          let notificationShown = false; // Флаг, чтобы показать уведомление только один раз
          
          messageSnapshot.forEach((messageSnap) => {
            const message = messageSnap.val();

            // Проверяем, что сообщение непрочитанное и отправлено пользователем
            if (!message.isRead && message.senderRole === 'user' && !notificationShown) {
              // Уведомление о новом сообщении
              notification.open({
                message: `Nowa wiadomość od ${message.sender}`,
                description: message.text,
                placement: 'topRight',
                onClick: () => {
                  navigate(`/chat`, { state: { selectedChatId: chat.id } });
                },
              });

              notificationShown = true; // Устанавливаем флаг, чтобы не показывать больше уведомлений для этого чата
            }
          });
        });

        if (chat.status === 'open') {
          openChats.push(chat);
        }
      });
      setChats(openChats);
    });

    // Отписка от обновлений при размонтировании компонента
    return () => unsubscribe();
  }, [navigate]);

  const openChat = (chatId) => {
    navigate(`/chat`, { state: { selectedChatId: chatId } });
  };

  return (
    <Card title="Otwarte zgłoszenia">
      <List
        itemLayout="horizontal"
        dataSource={chats}
        renderItem={chat => (
          <List.Item
            actions={[
              <Button type="primary" onClick={() => openChat(chat.id)}>
                Przejdź do rozmowy
              </Button>
            ]}
          >
            <List.Item.Meta
              title={`Temat: ${chat.chatName || 'Nieизвестный чат'}`}
              description={
                <>
                  <p>Kierowca: {chat.creatorName || 'Nieznany użytkownik'}</p>
                  <p>Status: {chat.status || 'Nieизвестно'}</p>
                  <p>Data utworzenia: {chat.timestamp ? new Date(chat.timestamp).toLocaleString('pl-PL') : 'Nieизвестно'}</p> {/* Проверка даты */}
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default OpenChats;
