import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Spin, Modal } from 'antd';
import { firestore } from '../firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import UserProfile from './UserProfile';

const Uzytkownicy = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(firestore, 'users');
        const q = query(usersCollection, orderBy('driverId'));
        const usersSnapshot = await getDocs(q);

        const usersData = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          status: doc.data().status,
          signedUZ: doc.data().signedUZ ? 'Tak' : 'Nie'
        }));

        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredUsers = users.filter(user => {
    const userText = `${user.firstName} ${user.lastName} ${user.email} ${user.status} ${user.signedUZ}`.toLowerCase();
    return userText.includes(searchQuery);
  });

  const handleViewProfile = (userId) => {
    setSelectedUser(userId);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      title: 'Imię i Nazwisko',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record.firstName} ${record.lastName}`
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'UZ Podpisana',
      dataIndex: 'signedUZ',
      key: 'signedUZ',
    },
    {
      title: 'Profil',
      key: 'profile',
      render: (_, record) => (
        <Button onClick={() => handleViewProfile(record.id)}>
          Zobacz profil
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Input
        placeholder="Szukaj użytkownika"
        value={searchQuery}
        onChange={handleSearch}
        style={{ marginBottom: '20px' }}
      />
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table columns={columns} dataSource={filteredUsers} rowKey="id" />
      )}

      <Modal
        title="Profil użytkownika"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        {selectedUser && <UserProfile userId={selectedUser} />}
      </Modal>
    </div>
  );
};

export default Uzytkownicy;
