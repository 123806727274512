import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, database, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, onValue, push, set, update } from 'firebase/database';
import { Modal, Button, Input, List, notification, Upload, Badge, Select, Form, Card, Divider } from 'antd';
import { UploadOutlined, FileOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Chat.css';
import { collection, addDoc } from 'firebase/firestore';

const { Option } = Select;

const Chat = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
  const [creatorName, setCreatorName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [chatStatus, setChatStatus] = useState('open');
  const [closeReason, setCloseReason] = useState('');
  const [imageModalUrl, setImageModalUrl] = useState('');

  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();  // Получаем объект location
  const storage = getStorage();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserInfo(userDocSnap.data());
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const chatsRef = ref(database, 'chats');
    onValue(chatsRef, (snapshot) => {
      let chats = [];
      snapshot.forEach((snap) => {
        const chat = { id: snap.key, ...snap.val() };
  
        // Подсчет непрочитанных сообщений
        let unreadCount = 0;
        const messagesRef = ref(database, `chats/${chat.id}/messages`);
        onValue(messagesRef, (messageSnapshot) => {
          messageSnapshot.forEach((messageSnap) => {
            const message = messageSnap.val();
  
            if (user && !message.isRead && message.senderRole === 'user') {
              unreadCount++;
            }
            
          });
          chat.unreadCount = unreadCount; // Сохраняем количество непрочитанных сообщений в объект чата
        });
  
        chats.push(chat);
      });
  
      // Сортировка чатов: сначала с непрочитанными сообщениями, затем с прочитанными
      chats.sort((a, b) => b.unreadCount - a.unreadCount);
  
      setChats(chats);
  
      // Если есть параметр selectedChatId, открываем соответствующий чат
      if (location.state && location.state.selectedChatId) {
        const chatToOpen = chats.find(chat => chat.id === location.state.selectedChatId);
        if (chatToOpen) {
          openChat(chatToOpen.id);
        }
      }
    });
  }, [user, location.state]);
  




  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getCreatorName = async (creatorId) => {
    const creatorDocRef = doc(firestore, 'users', creatorId);
    const creatorDocSnap = await getDoc(creatorDocRef);
    if (creatorDocSnap.exists()) {
      setCreatorName(`${creatorDocSnap.data().firstName} ${creatorDocSnap.data().lastName}`);
    } else {
      setCreatorName('Nieznany użytkownik');
    }
  };

  const addAdminToChat = async (chat) => {
    const adminsRef = ref(database, `chats/${chat.id}/admins`);

    // Проверяем наличие данных пользователя перед добавлением в администраторы
    const firstName = userInfo.firstName || "Nieznany";
    const lastName = userInfo.lastName || "Administrator";

    console.log(`Trying to add admin to chat: ${chat.id}`);

    onValue(adminsRef, async (snapshot) => {
        let admins = snapshot.val() || [];

        if (user && user.email) {
            const adminExists = admins.some(admin => admin.email === user.email);
            console.log(`Admin exists: ${adminExists}`);

            if (!adminExists) {
                const newAdmin = {
                    email: user.email,
                    firstName,  // Убедитесь, что используется значение firstName
                    lastName,   // Убедитесь, что используется значение lastName
                };

                console.log('Adding new admin:', newAdmin);

                admins.push(newAdmin);

                // Убедитесь, что все поля корректны перед вызовом set
                try {
                    await set(adminsRef, admins);
                    console.log('Admin successfully added');
                } catch (error) {
                    console.error('Error adding admin:', error);
                }
            }
        } else {
            console.warn('User or user email is not defined');
        }
    });
};

  
  


  const openChat = (chatId) => {
    const chat = chats.find(c => c.id === chatId);
    if (chat) {
        setSelectedChat(chat);
        setIsModalVisible(true);

        const messagesRef = ref(database, `chats/${chat.id}/messages`);
        onValue(messagesRef, (snapshot) => {
            let msgs = [];
            let lastMessage = null;

            snapshot.forEach((snap) => {
                const msg = snap.val();

                // Находим последнее сообщение
                if (!lastMessage || new Date(msg.timestamp) > new Date(lastMessage.timestamp)) {
                    lastMessage = { ...msg, id: snap.key };
                }

                // Обновляем статус только для сообщений, отправленных пользователем
                if (!msg.isRead && msg.senderRole === 'user') {
                    set(ref(database, `chats/${chat.id}/messages/${snap.key}/isRead`), true);
                    set(ref(database, `chats/${chat.id}/messages/${snap.key}/readAt`), new Date().toISOString());
                }

                msgs.push(msg);
            });

            // Обновляем lastMessage в объекте чата
            chat.lastMessage = lastMessage;

            setMessages(msgs);
            scrollToBottom();
        });

        addAdminToChat(chat); // Добавление администратора к чату
    }
};



  const sendMessage = async () => {
    if ((!message.trim() && !selectedFile) || !selectedChat) return;

    const senderName = `${userInfo.firstName} z zespołu wsparcia`;
    const senderRole = 'admin';

    let fileUrl = null;
    let fileType = null;
    if (selectedFile) {
      const fileRef = storageRef(storage, `chatFiles/${selectedChat.id}/${Date.now()}_${selectedFile.name}`);
      await uploadBytes(fileRef, selectedFile);
      fileUrl = await getDownloadURL(fileRef);
      fileType = selectedFile.type;
    }

    const msg = {
      text: message,
      fileUrl: fileUrl || null,
      fileType: fileType || null,
      sender: senderName,
      senderId: user.uid,
      senderRole: senderRole,
      timestamp: new Date().toISOString(),
      isRead: false,
      readAt: null,
    };
    
    await push(ref(database, `chats/${selectedChat.id}/messages`), msg);

    await set(ref(database, `chats/${selectedChat.id}/lastMessage`), {
      text: message,
      fileUrl: fileUrl || null,
      fileType: fileType || null,
      senderName: senderName,
      senderRole: senderRole,
      timestamp: msg.timestamp,
      isRead: false,
    });

    const notification = {
      title: "Nowa wiadomość w czacie",
      text: `Otrzymano nową wiadomość od ${senderName}: ${message}`,
      createdAt: new Date(),
      isRead: false,
    };

    const notificationsRef = collection(firestore, `users/${selectedChat.creatorId}/notifications`);
    await addDoc(notificationsRef, notification);

    setMessage('');
    setSelectedFile(null);
    setPreviewImageUrl(null);
    scrollToBottom();
  };


  const handleFileChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (file instanceof Blob) {
      setSelectedFile(file);
      if (file.type.startsWith('image/')) {
        const previewUrl = URL.createObjectURL(file);
        setPreviewImageUrl(previewUrl);
      } else {
        setPreviewImageUrl(null);
      }
    } else {
      console.error('The selected file is not a valid Blob or File object');
    }
  };

  const removeSelectedImage = () => {
    setSelectedFile(null);
    setPreviewImageUrl(null);
  };

  const closeChat = async () => {
    if (!selectedChat || !closeReason.trim()) return;

    await update(ref(database, `chats/${selectedChat.id}`), {
      status: 'closed',
      closedBy: user.uid,
      closeReason,
      closedAt: new Date().toISOString(),
    });

    setIsCloseModalVisible(false);
    setIsModalVisible(false);
    setSelectedChat(null);
    setCloseReason('');
  };

  const formatMessages = () => {
    const formattedMessages = [];
    let lastDate = '';
    let hasAddedUnreadDivider = false; // Флаг для добавления разделителя

    messages.forEach((msg, index) => {
      const messageDate = new Intl.DateTimeFormat('pl-PL', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(msg.timestamp));

      if (messageDate !== lastDate) {
        formattedMessages.push(
          <div key={messageDate} className="date-separator">
            {messageDate}
          </div>
        );
        lastDate = messageDate;
      }

      const isOwnMessage = msg.senderRole === 'admin';
      const senderName = isOwnMessage ? "Ty" : msg.sender;

      // Если сообщение непрочитанное и разделитель еще не добавлен, вставляем его
      if (!msg.isRead && !hasAddedUnreadDivider) {
        formattedMessages.push(
          <Divider key="unread-divider" orientation="center">
            Nieprzeczytane wiadomości
          </Divider>
        );
        hasAddedUnreadDivider = true;
      }

      formattedMessages.push(
        <div key={msg.timestamp} className={`message-item ${isOwnMessage ? 'you' : 'other'}`}>
          <strong>{senderName}:</strong>
          <div className="message-content">
            {msg.text && <div>{msg.text}</div>}
            {msg.fileUrl && msg.fileType && msg.fileType.startsWith('image/') && (
              <img
                src={msg.fileUrl}
                alt="Image"
                style={{ maxWidth: '100%', cursor: 'pointer' }}
                onClick={() => openImageModal(msg.fileUrl)}
              />
            )}
            {msg.fileUrl && msg.fileType && !msg.fileType.startsWith('image/') && (
              <div>
                <FileOutlined style={{ fontSize: '24px', marginRight: '8px' }} />
                <a href={msg.fileUrl} download>
                  {truncateFileName(extractFileName(msg.fileUrl))}
                </a>
              </div>
            )}
          </div>

          <div className="message-time">
            {new Intl.DateTimeFormat('pl-PL', {
              hour: 'numeric',
              minute: 'numeric',
            }).format(new Date(msg.timestamp))}
            <span className="message-status">
              {msg.isRead ? (
                <>
                  <span className="double-checks">✓✓</span>
                  {msg.readAt && (
                    <span className="read-time">
                      (Przeczytano: {new Intl.DateTimeFormat('pl-PL', {
                        hour: 'numeric',
                        minute: 'numeric',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      }).format(new Date(msg.readAt))})
                    </span>
                  )}
                </>
              ) : (
                <span className="single-check">✓</span>
              )}
            </span>
          </div>
        </div>
      );

      if (index === messages.length - 1) {
        formattedMessages.push(<div key="end" ref={messagesEndRef} />);
      }
    });

    return formattedMessages;
  };




  const renderChatHeader = () => {
    if (!selectedChat) return null;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '20px' }}>
        <div>
          <h2 style={{ margin: 0 }}>{selectedChat.chatName}</h2>
          <div style={{ color: 'gray', fontSize: '14px' }}>
            Rozmowa z: {creatorName}
          </div>
        </div>
        {selectedChat.status === 'open' && (
          <Button onClick={() => setIsCloseModalVisible(true)}>
            Zamknij czat
          </Button>
        )}
      </div>
    );
  };


  const openImageModal = (imageUrl) => {
    setImageModalUrl(imageUrl);
    setIsModalVisible(true);
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (fileName.length <= maxLength) return fileName;
    const extIndex = fileName.lastIndexOf('.');
    const extension = extIndex > -1 ? fileName.slice(extIndex) : '';
    const baseName = extIndex > -1 ? fileName.slice(0, extIndex) : fileName;

    return `${baseName.slice(0, maxLength - extension.length - 3)}...${extension}`;
  };

  const extractFileName = (url) => {
    try {
      const fullPath = decodeURIComponent(url.split('?')[0]);
      return fullPath.substring(fullPath.lastIndexOf('/') + 1);
    } catch (error) {
      return 'File';
    }
  };

  const filteredChats = chats.filter(chat => chat.status === chatStatus);

  return (
    <div className="chat-container">
      <Select
        defaultValue="open"
        onChange={setChatStatus}
        style={{ width: 200, marginBottom: 20 }}
      >
        <Option value="open">Otwarty</Option>
        <Option value="closed">Zamknięty</Option>
      </Select>
      <div className="chat-list">
        <h2>Chaty z kierowcami</h2>
        <List
          itemLayout="vertical"
          dataSource={filteredChats}
          renderItem={(chat) => (
            <List.Item>
              <Card
                title={
                  <Badge count={chat.unreadCount} offset={[12, 5]} style={{ backgroundColor: chat.unreadCount > 0 ? '#f5222d' : '#52c41a' }}>
                    {chat.chatName}
                  </Badge>
                }
                extra={<Button type="primary" onClick={() => openChat(chat.id)}>Wchodzę do czatu</Button>}
                style={{ width: '100%' }}
              >
                <div>Status: {chat.status}</div>
                <div>Utworzono: {new Date(chat.timestamp).toLocaleString('pl-PL')}</div>
              </Card>
            </List.Item>
          )}
        />

      </div>



      <Modal
        title={renderChatHeader()}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        style={{ top: 20 }}
        width={1000}
      >
        {selectedChat ? (
          <div className="chat-window">
            <div className="messages">{formatMessages()}</div>
            <div className="message-input">
              {previewImageUrl && selectedFile && selectedFile.type.startsWith('image/') ? (
                <div className="image-preview">
                  <img
                    src={previewImageUrl}
                    alt="Preview"
                    style={{ maxWidth: '100px', marginRight: '10px' }}
                  />
                  <CloseCircleOutlined
                    onClick={removeSelectedImage}
                    style={{ fontSize: '24px', color: 'red', cursor: 'pointer' }}
                  />
                </div>
              ) : selectedFile ? (
                <div className="file-preview">
                  <FileOutlined />
                  <span>{truncateFileName(selectedFile.name)}</span>
                  <CloseCircleOutlined
                    onClick={removeSelectedImage}
                    style={{ fontSize: '24px', color: 'red', cursor: 'pointer' }}
                  />
                </div>
              ) : null}


              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onPressEnter={sendMessage}
                  placeholder="Napisz wiadomość..."
                  style={{ flex: 1, marginRight: '10px' }}
                />
                <Upload
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  showUploadList={false}
                  className="upload-button"
                >
                  <Button icon={<UploadOutlined />} />
                </Upload>
                <Button type="primary" onClick={sendMessage} style={{ marginLeft: '10px' }}>
                  Wyślij
                </Button>
              </div>
            </div>

          </div>
        ) : (
          <div className="no-chat-selected">Proszę wybrać czat</div>
        )}
      </Modal>

      <Modal
        title="Powód zamknięcia czatu"
        visible={isCloseModalVisible}
        onCancel={() => setIsCloseModalVisible(false)}
        onOk={closeChat}
        okText="Zamknij czat"
        cancelText="Anuluj"
      >
        <Form layout="vertical">
          <Form.Item label="Podaj powód zamknięcia">
            <Input.TextArea
              rows={4}
              value={closeReason}
              onChange={(e) => setCloseReason(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Podgląd obrazu"
        visible={!!imageModalUrl}
        onCancel={() => setImageModalUrl('')}
        footer={[
          <Button key="download" type="primary" href={imageModalUrl} download>
            Pobierz
          </Button>,
        ]}
      >
        <img src={imageModalUrl} alt="Modal Content" style={{ width: '100%' }} />
      </Modal>
    </div>
  );
};

export default Chat;
