import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Modal, Select, message, Row, Col } from 'antd';
import { database } from '../firebase';
import { ref, onValue, update, remove } from 'firebase/database';

const { Option } = Select;

const Kierowcy = () => {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [cars, setCars] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [zusFilter, setZusFilter] = useState('');
  const [workStatusFilter, setWorkStatusFilter] = useState('');
  const [isPartnerModalVisible, setIsPartnerModalVisible] = useState(false);
  const [isZusModalVisible, setIsZusModalVisible] = useState(false);
  const [currentDriverId, setCurrentDriverId] = useState(null);
  const [newPartnerValue, setNewPartnerValue] = useState('');
  const [newZusValue, setNewZusValue] = useState('');

  useEffect(() => {
    const fetchCarsAndDrivers = async () => {
      const carsRef = ref(database, 'admin/carsrent');
      const driversRef = ref(database, 'drivers');

      onValue(carsRef, (snapshot) => {
        setCars(snapshot.val() || {});
      });

      onValue(driversRef, (snapshot) => {
        const driversData = snapshot.val() || {};
        const driversArray = Object.entries(driversData).map(([driverId, driver]) => ({ driverId, ...driver }));

        driversArray.sort((a, b) => {
          const secondWordA = a.driverId.split(' ')[1] || '';
          const secondWordB = b.driverId.split(' ')[1] || '';
          return secondWordA.localeCompare(secondWordB);
        });

        setDrivers(driversArray);
        setFilteredDrivers(driversArray);
      });
    };

    fetchCarsAndDrivers();
  }, []);

  useEffect(() => {
    const filtered = drivers.filter(driver => {
      const matchesStatus = statusFilter === '' || driver.status === statusFilter;
      const matchesZus = zusFilter === '' || driver.zusType === zusFilter;
      const matchesWorkStatus = workStatusFilter === '' || driver.workStatus === workStatusFilter;
      const matchesSearch = driver.driverId.toLowerCase().includes(searchInput.toLowerCase());

      return matchesStatus && matchesZus && matchesWorkStatus && matchesSearch;
    });
    setFilteredDrivers(filtered);
  }, [statusFilter, zusFilter, workStatusFilter, searchInput, drivers]);

  const handleZusChange = (driverId, value) => {
    update(ref(database, `drivers/${driverId}/zusType`), value);
  };

  const handleWorkStatusChange = (driverId, value) => {
    const updateData = {
      workStatus: value,
      zusType: value === 'Uczeń/Student' ? 'Uczeń/Student' : 'zus',
      pit: value !== 'Uczeń/Student' && value !== 'Osoba do 26 roku życia',
    };
    update(ref(database, `drivers/${driverId}`), updateData);
  };

  const handleStatusChange = (driverId, value) => {
    update(ref(database, `drivers/${driverId}`), { status: value });
  };

  const handlePartnerValueChange = (e) => {
    setNewPartnerValue(e.target.value);
  };

  const savePartnerValue = () => {
    update(ref(database, `drivers/${currentDriverId}`), { valuePartner: newPartnerValue }).then(() => {
      setIsPartnerModalVisible(false);
    }).catch((error) => {
      console.error("Error updating partner value:", error);
      message.error("Błąd przy aktualizacji wartości partnera.");
    });
  };

  const handleZusValueChange = (e) => {
    setNewZusValue(e.target.value);
  };

  const saveZusValue = () => {
    update(ref(database, `drivers/${currentDriverId}`), { valueZUS: newZusValue }).then(() => {
      setIsZusModalVisible(false);
    }).catch((error) => {
      console.error("Error updating ZUS value:", error);
      message.error("Błąd przy aktualizacji wartości ZUS.");
    });
  };

  const handleDeleteDriver = (driverId) => {
    remove(ref(database, `drivers/${driverId}`)).then(() => {
      alert(`Driver ${driverId} pomyślnie usunięto`);
    }).catch((error) => {
      console.error(`Błąd przy usuwaniu kierowcy ${driverId}`, error);
    });
  };

  const fetchZusFromAdmin = () => {
    const zusRef = ref(database, 'admin/valueZUS');
    onValue(zusRef, (snapshot) => {
      const adminZusValue = snapshot.val();
      if (adminZusValue) {
        setNewZusValue(adminZusValue);
      } else {
        message.error("Nie udało się pobrać wartości ZUS z admin.");
      }
    });
  };

  const columns = [
    {
      title: 'ID Kierowcy',
      dataIndex: 'driverId',
      key: 'driverId',
      render: (text) => {
        const [firstName, lastName] = text.split(' ');
        return `${lastName} ${firstName}`;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handleStatusChange(record.driverId, value)}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      )
    },
    {
      title: 'Value ZUS',
      dataIndex: 'valueZUS',
      key: 'valueZUS',
      render: (text, record) => (
        <Button
          onClick={() => {
            setCurrentDriverId(record.driverId);
            setNewZusValue(text || '');
            setIsZusModalVisible(true);
          }}
          disabled={record.status !== 'active'}
        >
          {text || 'Brak danych'}
        </Button>
      )
    },
    {
      title: 'ZUS Type',
      dataIndex: 'zusType',
      key: 'zusType',
      render: (zusType, record) => (
        <Select
          value={zusType}
          onChange={(value) => handleZusChange(record.driverId, value)}
          disabled={record.workStatus === 'Uczeń/Student' || record.status !== 'active'}
        >
          <Option value="zus">zus</Option>
          <Option value="zusWorkContract">zusWorkContract</Option>
          <Option value="Uczeń/Student">Uczeń/Student</Option>
        </Select>
      )
    },
    {
      title: 'Partner',
      dataIndex: 'valuePartner',
      key: 'valuePartner',
      render: (text, record) => (
        <Button
          onClick={() => {
            setCurrentDriverId(record.driverId);
            setNewPartnerValue(text);
            setIsPartnerModalVisible(true);
          }}
          disabled={record.status !== 'active'}
        >
          {text || 'N/A'}
        </Button>
      )
    },
    {
      title: 'Work Status',
      dataIndex: 'workStatus',
      key: 'workStatus',
      render: (workStatus, record) => (
        <Select
          value={workStatus}
          onChange={(value) => handleWorkStatusChange(record.driverId, value)}
          disabled={record.status !== 'active'}
        >
          <Option value="Uczeń/Student">Uczeń/Student</Option>
          <Option value="Osoba nie pracująca gdzie indziej">Osoba nie pracująca gdzie indziej</Option>
          <Option value="Osoba pracująca gdzie indziej">Osoba pracująca gdzie indziej</Option>
          <Option value="Osoba do 26 roku życia">Osoba do 26 roku życia</Option>
        </Select>
      )
    },
    {
      title: 'PIT',
      dataIndex: 'pit',
      key: 'pit',
      render: (pit) => (pit ? 'Tak' : 'Nie')
    },
    {
      title: 'Samochód',
      dataIndex: 'car',
      key: 'car',
      render: (car, record) => {
        if (car && cars[car]) {
          const carData = cars[car];
          return `${carData.make} ${carData.model}`;
        }
        return 'Brak samochodu';
      }
    },
    {
      title: 'Usuń',
      key: 'delete',
      render: (_, record) => (
        <Button
          danger
          onClick={() => handleDeleteDriver(record.driverId)}
          disabled={record.status !== 'inactive'}
        >
          Usuń
        </Button>
      )
    }
  ];

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <Col flex="auto">
          <Input
            placeholder="Szukaj kierowcy"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col flex="200px">
          <Select
            placeholder="Status"
            value={statusFilter}
            onChange={setStatusFilter}
            style={{ width: '100%' }}
          >
            <Option value="">Wszystkie</Option>
            <Option value="active">Active</Option>
            <Option value="inactive">Inactive</Option>
          </Select>
        </Col>
        <Col flex="200px">
          <Select
            placeholder="ZUS"
            value={zusFilter}
            onChange={setZusFilter}
            style={{ width: '100%' }}
          >
            <Option value="">Wszystkie</Option>
            <Option value="zus">zus</Option>
            <Option value="zusWorkContract">zusWorkContract</Option>
            <Option value="Uczeń/Student">Uczeń/Student</Option>
          </Select>
        </Col>
        <Col flex="200px">
          <Select
            placeholder="Work Status"
            value={workStatusFilter}
            onChange={setWorkStatusFilter}
            style={{ width: '100%' }}
          >
            <Option value="">Wszystkie</Option>
            <Option value="Uczeń/Student">Uczeń/Student</Option>
            <Option value="Osoba nie pracująca gdzie indziej">Osoba nie pracująca gdzie indziej</Option>
            <Option value="Osoba pracująca gdzie indziej">Osoba pracująca gdzie indziej</Option>
            <Option value="Osoba do 26 roku życia">Osoba do 26 roku życia</Option>
          </Select>
        </Col>
      </Row>

      <Table columns={columns} dataSource={filteredDrivers} rowKey="driverId" />

      <Modal
        title="Edycja ZUS"
        visible={isZusModalVisible}
        onCancel={() => setIsZusModalVisible(false)}
        onOk={saveZusValue}
      >
        <Input value={newZusValue} onChange={handleZusValueChange} />
        <Button onClick={fetchZusFromAdmin} style={{ marginTop: '10px' }}>
          Ustaw wartość minimalną
        </Button>
      </Modal>

      <Modal
        title="Edycja Partnera"
        visible={isPartnerModalVisible}
        onCancel={() => setIsPartnerModalVisible(false)}
        onOk={savePartnerValue}
      >
        <Input value={newPartnerValue} onChange={handlePartnerValueChange} />
      </Modal>
    </div>
  );
};

export default Kierowcy;
