import React, { useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import AuthLayout from '../components/AuthLayout';

const { Title, Text } = Typography;

const Register = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await createUserWithEmailAndPassword(auth, values.email, values.password);
      message.success('Rejestracja zakończona sukcesem');
      message.info('Proszę czekać na zatwierdzenie przez administratora');
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div style={{ textAlign: 'center', marginBottom: '24px' }}>
        <Title level={2}>Rejestracja</Title>
        <Text>Jeśli masz już konto, proszę <Link to="/login">zaloguj się tutaj</Link>.</Text>
      </div>
      <Form name="register" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Proszę podać email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Hasło"
          name="password"
          rules={[{ required: true, message: 'Proszę podać hasło!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Zarejestruj się
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};

export default Register;
