import React, { useState, useEffect } from 'react';
import { Menu, Badge } from 'antd';
import {
  DashboardOutlined,
  CarOutlined,
  UserOutlined,
  FileOutlined,
  BankOutlined,
  TeamOutlined,
  MessageOutlined,
  FileTextOutlined,
  FileSearchOutlined,
  FundViewOutlined
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebase'; // Импортируйте свою конфигурацию Firebase

const SideMenu = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  useEffect(() => {
    const messagesRef = ref(database, 'chats');
    onValue(messagesRef, (snapshot) => {
      let unreadCount = 0;
      snapshot.forEach((chatSnap) => {
        const chat = chatSnap.val();
        if (chat.messages) {
          Object.values(chat.messages).forEach((msg) => {
            if (!msg.isRead && msg.senderRole !== 'admin') {
              unreadCount++;
            }
          });
        }
      });
      setUnreadMessagesCount(unreadCount);
    });
  }, []);

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const handleMenuClick = (e) => {
    navigate(e.key);
    setSelectedKey(e.key);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={[selectedKey]}
      onClick={handleMenuClick}
      items={[
        {
          key: 'g1',
          label: 'Dashboard',
          type: 'group',
          children: [
            {
              key: '/',
              icon: <DashboardOutlined />,
              label: 'Dashboard',
            },
          ],
        },
        {
          key: 'g2',
          label: 'Komunikacja',
          type: 'group',
          children: [
            {
              key: '/chat',
              icon: <MessageOutlined />,
              label: (
                <span>
                  Chat
                  {unreadMessagesCount > 0 && (
                    <Badge count={unreadMessagesCount} offset={[5, -5]} style={{ backgroundColor: '#f5222d' }} />
                  )}
                </span>
              ),
            },
            {
              key: '/powiadomienia',
              icon: <TeamOutlined />,
              label: 'Powiadomienia',
            },
          ],
        },
        {
          key: 'g3',
          label: 'Rozliczenia',
          type: 'group',
          children: [
            {
              key: '/rozliczenie',
              icon: <FundViewOutlined />,
              label: 'Rozliczenie',
            },
            {
              key: '/faktury',
              icon: <FileSearchOutlined />,
              label: 'Faktury',
            },
            {
              key: '/wydatki',
              icon: <FileSearchOutlined />,
              label: 'Wydatki',
            },
            {
              key: '/bonus',
              icon: <FileSearchOutlined />,
              label: 'Bonus Partnera',
            },
            {
              key: '/przelewy',
              icon: <BankOutlined />,
              label: 'Przelewy',
            },
          ],
        },
        {
          key: 'g4',
          label: 'Zarządzaj',
          type: 'group',
          children: [
            {
              key: '/kierowcy',
              icon: <UserOutlined />,
              label: 'Kierowcy',
            },
            {
              key: '/uzytkownicy',
              icon: <TeamOutlined />,
              label: 'Użytkownicy',
            },
          ],
        },
        {
          key: 'g5',
          label: 'Flota',
          type: 'group',
          children: [
            {
              key: '/samochody',
              icon: <CarOutlined />,
              label: 'Samochody',
            },
    
          ],
        },
        {
          key: 'g6',
          label: 'Dokumenty',
          type: 'group',
          children: [
            {
              key: '/raport',
              label: 'Raport',
              icon: <FileTextOutlined />,
            },
            {
              key: '/faktury-zw',
              label: 'Faktury ZW',
              icon: <FileTextOutlined />,
            },
            {
              key: '/pit-28',
              label: 'PIT 28',
              icon: <FileTextOutlined />,
            },
            {
              key: '/raporty-ksiegowe',
              label: 'Raporty księgowe',
              icon: <FileTextOutlined />,
            },
            {
              key: '/jpk-vat',
              label: 'JPK VAT',
              icon: <FileTextOutlined />,
            },
          ],
        },
      ]}
    />
  );
};

export default SideMenu;
