import React from 'react';
import { Layout } from 'antd';
import logo from '../assets/spario.png';

const { Header, Footer, Content } = Layout;

const AuthLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 50px' }}>
        <img src={logo} alt="Logo" style={{ height: '32px' }} />
        <div>Pomoc: +48 123 456 789</div>
      </Header>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '50px' }}>
        <div style={{ width: '100%', maxWidth: '400px' }}>
          {children}
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>©2024 ColorParner Sp. z o.o. Wszelkie prawa zastrzeżone.</Footer>
    </Layout>
  );
};

export default AuthLayout;
