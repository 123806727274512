import React from 'react';

const Raport = () => {
  return (
    <div>
      <h1>Raport</h1>
    </div>
  );
};

export default Raport;
