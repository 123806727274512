import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Импорт Firebase Storage

const firebaseConfig = {
    apiKey: "AIzaSyDVz0ho0nQb7Sh9o_0wFLZfVJM7aeGYgb0",
    authDomain: "ccmcolorpartner.firebaseapp.com",
    databaseURL: "https://ccmcolorpartner-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ccmcolorpartner",
    storageBucket: "ccmcolorpartner.appspot.com", // Указание storageBucket
    messagingSenderId: "417966588632",
    appId: "1:417966588632:web:bf85b1ff7307bb86de8dad",
    measurementId: "G-WQP6RE6CQ3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
const storage = getStorage(app); // Инициализация Firebase Storage

export { auth, database, firestore, storage };
