import React from 'react';

const PIT28 = () => {
  return (
    <div>
      <h1>PIT 28</h1>
    </div>
  );
};

export default PIT28;
