import React from 'react';

const FakturyZW = () => {
  return (
    <div>
      <h1>Faktury ZW</h1>
    </div>
  );
};

export default FakturyZW;
